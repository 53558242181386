import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ParcelLogo from 'assets/icons/parcel-logo-2.svg';
import LogoutIcon from 'assets/icons/sidebar/logout-icon.svg';
import CopyButton from 'components/common/Copy';
import EtherscanLink from 'components/common/EtherscanLink';
import Img from 'components/common/Img';
import { minifyAddress } from 'components/common/Web3Utils';
import { useActiveWeb3React, useDropdown } from 'hooks';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import { DashboardTopBar } from './styles';
import { logoutUser } from 'store/logout/actions';
import SupportIcon from 'assets/icons/sidebar/support.svg';

const TopBar = ({ openSidebar, closeSidebar }) => {
  const { open, toggleDropdown } = useDropdown();
  const dispatch = useDispatch();

  const { onboard, account, connector } = useActiveWeb3React();
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());

  const iconSrc = connector?.icons?.iconSrc || '';
  const name = connector?.name || '';

  const logout = () => {
    if (onboard) {
      onboard.walletReset();
    }
    dispatch(logoutUser());
  };

  return (
    <DashboardTopBar className="d-flex align-items-center">
      <div className="parcel-logo-container d-flex align-items-center">
        <div className="nav-icon" onClick={openSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <Img src={ParcelLogo} alt="parcel" width="100%" />
      </div>
      <div className="d-flex align-items-center" style={{ height: '100%' }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.gg/Kg78anBhsM"
          className="support-container d-flex align-items-center"
        >
          <Img src={SupportIcon} alt="support" />
          <div>Support</div>
        </a>
        <div className="wallet-details-container d-flex align-items-center">
          <div>
            {iconSrc && (
              <Img className="wallet-logo" src={iconSrc} alt="wallet-logo" />
            )}
          </div>
          <div className="wallet-details">
            <div className="name">{name}</div>
            <div className="address">{minifyAddress(account)}</div>
          </div>
          <div className="actions-container d-flex align-items-center">
            <CopyButton id="address" tooltip="wallet address" value={account} />
            <span style={{ marginLeft: '1.3rem' }}>
              <EtherscanLink
                id="etherscan-link-navbar-safe"
                type="address"
                address={ownerSafeAddress}
              />
            </span>
            <FontAwesomeIcon
              className="dropdown"
              icon={faAngleDown}
              onClick={toggleDropdown}
            />
            <div className={`disconnect-dropdown ${open && 'show'}`}>
              <div className="disconnect-option" onClick={logout}>
                <div className="name">Disconnect</div>
                <div className="icon">
                  <Img src={LogoutIcon} alt="logout" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardTopBar>
  );
};

export default TopBar;
