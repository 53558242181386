import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components/macro';

import 'react-toastify/dist/ReactToastify.min.css';

const StyledContainer = styled(ToastContainer).attrs({
  // custom props
})`
  &.Toastify__toast-container {
    position: absolute;
    // width: 1200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .Toastify__toast {
    width: 280px;
    min-height: 40px;
    padding: 12px 12px 15px 24px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  &.Toastify__toast-container--top-right {
    top: 7em;
    right: 1em;
    flex-direction: column;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
    font-family: "Avenir Pro", sans-serif;
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
  }

  .Toastify__progress-bar {
  }

  .Toastify__progress-bar--success {
    background: #6cb44c !important;
  }
  .Toastify__progress-bar--error {
    background: #ff4660 !important;
  }

  .Toastify__close-button {
    color: #aaaaaa;
  }

  .toast-body-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }

  .toast-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }

  .toast-transaction-status {
    width: 30px;
    height: 30px;
  }

  a {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    color: #7367f0;
    margin-top: 5px;

    &:hover {
      color: #373737;
    }
  }
`;
export function ToastMessage() {
  return (
    <StyledContainer
      position="top-right"
      autoClose={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
  );
}

export function showToast(msg, type, ...rest) {
  return toast[type](msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...rest,
  });
}

export const toaster = toast;
