import styled from 'styled-components/macro';

export default styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 0.1em solid #dddcdc;
  position: relative;
  padding-bottom: 3rem;

  .sidebar-container {
    position: sticky;
    top: 5rem;
    width: 100%;
  }
  &.sidebar-responsive {
    position: absolute;
    display: block;
    z-index: 2;
    left: 0;
    height: 100vh;
    max-width: 30em;
  }

  .safe-info-container {
    width: 100%;
    padding: 1rem 4rem 2rem 4rem;
    border-bottom: 0.1rem solid #dddcdc;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;

    .image {
      width: 5rem;
      height: 5rem;
      margin-bottom: 0.7rem;
    }

    .name {
      font-weight: 900;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #373737;
      align-items: flex-start;
      cursor: pointer;

      svg {
        width: 1rem;
        height: unset;
        margin-left: 1rem;
      }
    }

    .address {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #8d8e91;
      margin-top: 0.8rem;
    }
    .actions-container {
      margin-top: 1.2rem;

      & > img:not(:last-child) {
        margin-right: 1.3rem;
        cursor: pointer;
      }
      & > div:not(:last-child) {
        margin-right: 1.3rem;
        cursor: pointer;
      }
    }
    .amount {
      margin-top: 1.8rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #373737;
    }

    .cta {
      margin-top: 2rem;
    }
  }

  .network-name {
    background: #aaaaaa1a;
    width: 100%;
    height: 24px;
    /* opacity: 0.1; */
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #aaaaaa;
    justify-content: center;
  }

  .close-btn {
    display: none;
    position: absolute;
    right: 2rem;
    top: 4rem;
    cursor: pointer;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .menu-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5em;
  }

  .menu-item {
    height: 4.7rem;
    width: 100%;
    color: #373737;
    padding-left: 4rem;
    position: relative;

    &.menu-item-highlighted {
      width: 100%;
      background-color: #7367f01a;
      color: #7367f0;
      height: 4.7rem;
      padding: 0 4rem;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 0.4rem;
        height: 100%;
        background-color: #7367f0;
        left: 0;
      }
    }

    .icon {
      margin-right: 2rem;
      width: 2rem;
      height: 2rem;
    }

    .name {
      font-size: 1.6rem;
      font-weight: 500;
      margin-top: 0.2rem;
    }
  }

  @media (max-width: 978px) {
    display: none;

    .close-btn {
      display: block;
    }
  }
`;
