import styled from 'styled-components/macro';

export default styled.div`
  width: 100%;
  min-height: 8rem;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  margin-top: 1rem;

  .section {
    width: 50%;
    border: 0.5px solid #dddcdc;
    padding: 3rem;

    .left {
      float: left;

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }

      .subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;

        margin-top: 0.8rem;
      }
    }

    .right {
      float: right;

      .buttons {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .approve-button {
          display: flex;
          margin-right: 2rem;
          button {
            box-shadow: 1rem 1rem 2rem 0 rgba(35, 30, 79, 0.15);
          }
        }

        .reject-button {
          display: flex;
          button {
            box-shadow: 1rem 1rem 2rem 0 rgba(35, 30, 79, 0.15);
            background-color: #ff4660;
          }
        }

        .warning {
          margin: auto 0.8rem;
        }
      }
    }
  }
`;
