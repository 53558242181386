import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { cryptoUtils } from 'parcel-sdk';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'hooks';

import Export from 'components/People/styles/Export.js';
import ExportIcon from 'assets/icons/dashboard/export-icon.svg';
import Img from 'components/common/Img';
import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import { viewTransactions } from 'store/transactions/actions';
import {
  makeSelectTransactions,
  makeSelectFetching,
} from 'store/transactions/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import {
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import StatusText from './StatusText';
import { getTokens } from 'store/tokens/actions';
import { makeSelectTokenIcons } from 'store/tokens/selectors';
import tokensSaga from 'store/tokens/saga';
import tokensReducer from 'store/tokens/reducer';
import { Info } from 'components/Dashboard-old/styles';
import { Container } from './styles';
import {
  Table,
  TableLoader,
  TableInfo,
  TableHead,
  TableBody,
} from 'components/common/Table';
import { replaceAddresswithMinification } from 'components/common/Web3Utils';
import { formatNumber } from 'utils/number-helpers';
import TokenImg from 'components/common/TokenImg';

const transactionsKey = 'transactions';
const tokensKey = 'tokens';

export default function Transactions() {
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');

  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: tokensKey, reducer: tokensReducer });

  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });
  useInjectSaga({ key: tokensKey, saga: tokensSaga });

  const dispatch = useDispatch();
  const history = useHistory();

  const transactions = useSelector(makeSelectTransactions());
  const loading = useSelector(makeSelectFetching());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const icons = useSelector(makeSelectTokenIcons());
  const organisationType = useSelector(makeSelectOrganisationType());

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(viewTransactions(ownerSafeAddress));
    }
  }, [dispatch, ownerSafeAddress]);

  useEffect(() => {
    if (ownerSafeAddress && !icons) {
      dispatch(getTokens(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch, icons]);

  const getDecryptedDetails = data => {
    if (!encryptionKey) return '';
    return JSON.parse(
      cryptoUtils.decryptDataUsingEncryptionKey(
        data,
        encryptionKey,
        organisationType
      )
    );
  };

  const renderTransactions = () => {
    let csvData = [];
    if (transactions && transactions.length > 0) {
      transactions.map(transaction => {
        if (transaction && transaction.transactionHash) {
          const {
            transactionId,
            to,
            createdOn,
            transactionFees,
            transactionHash,
            paymentType,
            tokenCurrency,
            description,
          } = transaction;

          const paidTeammates = getDecryptedDetails(to);
          for (let i = 0; i < paidTeammates.length; i++) {
            const {
              firstName,
              lastName,
              salaryAmount,
              salaryToken,
              address,
              allowanceAmount,
              allowanceToken,
            } = paidTeammates[i];

            const derivedDetails = {
              type: '-',
              amount: '-',
              token: '-',
            };

            if (paymentType === 'limit') {
              derivedDetails.amount = allowanceAmount;
              derivedDetails.token = allowanceToken;
              derivedDetails.type = 'Spending Limit';
            } else {
              derivedDetails.amount = salaryAmount;
              if (salaryToken === 'USD') {
                derivedDetails.token = tokenCurrency;
              } else {
                derivedDetails.token = salaryToken;
              }

              switch (paymentType) {
                case 'quick':
                  derivedDetails.type = 'Quick Payment';
                  break;
                case 'custom':
                case 'individual':
                case 'team':
                  derivedDetails.type = 'Mass payout';
                  break;
                default:
                  derivedDetails.type = '-';
              }
            }

            csvData.push({
              'Payment Type': derivedDetails.type,
              'First Name': firstName || '-',
              'Last Name': lastName || '-',
              Token: derivedDetails.token,
              Amount: derivedDetails.amount,
              Address: address,
              description,
              'Transaction Hash': transactionHash || '',
              'Created On': format(new Date(createdOn), 'dd/MM/yyyy HH:mm:ss'),
              'Transaction ID': transactionId,
              'Transaction fees': transactionFees
                ? formatNumber(transactionFees, 5)
                : '',
            });
          }
        }
        return csvData;
      });
    }

    const renderTableContent = () => {
      if (loading) {
        return <TableLoader colSpan={6} height="30rem" />;
      }

      if (transactions && transactions.length > 0) {
        return (
          <>
            {transactions.map((transaction, idx) => {
              const {
                transactionId,
                tokenValue,
                tokenCurrency,
                // fiatValue,
                status,
                createdOn,
                description,
              } = transaction;

              return (
                <tr key={`${transactionId}-${idx}`}>
                  <td>{format(new Date(createdOn), 'dd/MM/yyyy')}</td>
                  <td>
                    {replaceAddresswithMinification(transaction?.paidTo, 6, 6)}
                  </td>
                  <td>
                    <TokenImg token={tokenCurrency} />
                    {parseFloat(tokenValue.toFixed(5))} {tokenCurrency}
                  </td>
                  <td>
                    <StatusText status={status} />
                  </td>
                  <td>{replaceAddresswithMinification(description, 6, 6)}</td>
                  <td
                    style={{
                      color: '#7367f0',
                      fontWeight: 900,
                      textAlign: 'center',
                    }}
                    onClick={() =>
                      history.push(`/dashboard/transactions/${transactionId}`)
                    }
                  >
                    View
                  </td>
                </tr>
              );
            })}
          </>
        );
      } else {
        return (
          <TableInfo
            style={{
              fontSize: '1.4rem',
              fontWeight: '500',
              textAlign: 'center',
              height: '10rem',
            }}
          >
            <td colSpan={6}>No transactions found!</td>
          </TableInfo>
        );
      }
    };

    return (
      <div
        className="position-relative"
        style={{
          transition: 'all 0.25s linear',
        }}
      >
        <Info>
          <div>
            <div className="title">Transactions</div>
            <div className="subtitle">Track your transaction status here</div>
          </div>
          <div>
            <CSVLink
              data={csvData}
              filename={`transactions-${format(
                Date.now(),
                'dd/MM/yyyy-HH:mm:ss'
              )}.csv`}
            >
              <Export>
                <div className="text">Export</div>
                <Img src={ExportIcon} alt="export" className="ml-2" />
              </Export>
            </CSVLink>
          </div>
        </Info>

        <Container>
          <Table style={{ marginTop: '3rem' }}>
            <TableHead>
              <tr>
                <th style={{ width: '12%' }}>Date</th>
                <th style={{ width: '20%' }}>Paid to</th>
                <th style={{ width: '13%' }}>Total amount</th>
                <th style={{ width: '10%' }}>Status</th>
                <th style={{ width: '15%' }}>Description</th>
                <th style={{ width: '10%' }}></th>
              </tr>
            </TableHead>
            <TableBody>{renderTableContent()}</TableBody>
          </Table>
        </Container>
      </div>
    );
  };

  return renderTransactions();
}
