function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export default function request(url, options) {
  const { avoidAuthHeaders, ...rest } = options;
  const token = localStorage.getItem('token');

  let headers;

  if (avoidAuthHeaders) {
    headers = {
      'content-type': 'application/json',
      ...rest.headers,
    };
  } else {
    headers = {
      'content-type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
      ...rest.headers,
    };
  }

  const finalOptions = {
    ...rest,
    headers,
  };
  return fetch(url, finalOptions).then(checkStatus).then(parseJSON);
}
