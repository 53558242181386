export const CREATE_MULTISIG_TRANSACTION =
  'parcel/multisig/CREATE_MULTISIG_TRANSACTION';
export const CREATE_MULTISIG_TRANSACTION_SUCCESS =
  'parcel/multisig/CREATE_MULTISIG_TRANSACTION_SUCCESS';
export const CREATE_MULTISIG_TRANSACTION_ERROR =
  'parcel/multisig/CREATE_MULTISIG_TRANSACTION_ERROR';

export const CONFIRM_MULTISIG_TRANSACTION =
  'parcel/multisig/CONFIRM_MULTISIG_TRANSACTION';
export const CONFIRM_MULTISIG_TRANSACTION_SUCCESS =
  'parcel/multisig/CONFIRM_MULTISIG_TRANSACTION_SUCCESS';
export const CONFIRM_MULTISIG_TRANSACTION_ERROR =
  'parcel/multisig/CONFIRM_MULTISIG_TRANSACTION_ERROR';

export const SUBMIT_MULTISIG_TRANSACTION =
  'parcel/multisig/SUBMIT_MULTISIG_TRANSACTION';
export const SUBMIT_MULTISIG_TRANSACTION_SUCCESS =
  'parcel/multisig/SUBMIT_MULTISIG_TRANSACTION_SUCCESS';
export const SUBMIT_MULTISIG_TRANSACTION_ERROR =
  'parcel/multisig/SUBMIT_MULTISIG_TRANSACTION_ERROR';

export const GET_MULTISIG_TRANSACTIONS =
  'parcel/multisig/GET_MULTISIG_TRANSACTIONS';
export const GET_MULTISIG_TRANSACTIONS_SUCCESS =
  'parcel/multisig/GET_MULTISIG_TRANSACTIONS_SUCCESS';
export const GET_MULTISIG_TRANSACTIONS_ERROR =
  'parcel/multisig/GET_MULTISIG_TRANSACTIONS_ERROR';

export const GET_MULTISIG_TRANSACTION_BY_ID =
  'parcel/multisig/GET_MULTISIG_TRANSACTION_BY_ID';
export const GET_MULTISIG_TRANSACTION_BY_ID_SUCCESS =
  'parcel/multisig/GET_MULTISIG_TRANSACTION_BY_ID_SUCCESS';
export const GET_MULTISIG_TRANSACTION_BY_ID_ERROR =
  'parcel/multisig/GET_MULTISIG_TRANSACTION_BY_ID_ERROR';

export const CLEAR_MULTISIG_TRANSACTION =
  'parcel/multisig/CLEAR_MULTISIG_TRANSACTION';

export const FETCH_MULTISIG_TRANSACTION_WITH_DELAY =
  'parcel/multisig/FETCH_MULTISIG_TRANSACTION_WITH_DELAY';

export const FETCH_MULTISIG_TRANSACTION_WITH_DELAY_SUCCESS =
  'parcel/multisig/FETCH_MULTISIG_TRANSACTION_WITH_DELAY_SUCCESS';
