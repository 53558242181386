// import NotificationSidebar from './NotificationSidebar';
import PeopleDetailsSidebar from 'components/People/PeopleDetailsSidebar';
import SafeDetailsSidebar from 'components/People/SafeDetailsSidebar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import layoutReducer from 'store/layout/reducer';
import { getTokenList, getTokens } from 'store/tokens/actions';
import tokensReducer from 'store/tokens/reducer';
import tokensSaga from 'store/tokens/saga';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import Sidebar from './Sidebar';
import { LayoutContainer, Main } from './styles';
import TopBar from './TopBar';

const layoutKey = 'layout';
const tokensKey = 'tokens';

export default function DashboardLayout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useInjectReducer({ key: layoutKey, reducer: layoutReducer });
  useInjectReducer({ key: tokensKey, reducer: tokensReducer });

  useInjectSaga({ key: tokensKey, saga: tokensSaga });

  const dispatch = useDispatch();

  const safeAddress = useSelector(makeSelectOwnerSafeAddress());

  useEffect(() => {
    if (safeAddress) {
      dispatch(getTokenList(safeAddress));
      dispatch(getTokens(safeAddress));
    }
  }, [dispatch, safeAddress]);

  const openSidebar = () => {
    setIsSidebarOpen(state => !state);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <React.Fragment>
      <TopBar
        isSidebarOpen={isSidebarOpen}
        closeSidebar={closeSidebar}
        openSidebar={openSidebar}
      />
      <LayoutContainer>
        <Sidebar isSidebarOpen={isSidebarOpen} closeSidebar={closeSidebar} />
        {/* <Navbar isSidebarOpen={isSidebarOpen} openSidebar={openSidebar} /> */}
        <Main>{children}</Main>
      </LayoutContainer>
      {/* <NotificationSidebar /> */}
      <PeopleDetailsSidebar />
      <SafeDetailsSidebar />
    </React.Fragment>
  );
}
