import { networkNames } from './networks';

// rinkeby
const RINKEBY_PROXY_FACTORY_ADDRESS =
  '0x76E2cFc1F5Fa8F6a5b3fC4c8F4788F0116861F9B';
const RINKEBY_GNOSIS_SAFE_ADDRESS =
  '0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F';
const RINKEBY_DAI_ADDRESS = '0xc3dbf84Abb494ce5199D5d4D815b10EC29529ff8';
const RINKEBY_USDC_ADDRESS = '0x472d88e5246d9bF2AB925615fc580336430679Ae';
const RINKEBY_USDT_ADDRESS = '0x897886a7d886349723Bf485502d8513CeC3350Ba';
const RINKEBY_WETH_ADDRESS = '0xc778417e063141139fce010982780140aa0cd5ab';
const RINKEBY_MULTISEND_ADDRESS = '0x8D29bE29923b68abfDD21e541b9374737B49cdAD';
const RINKEBY_UNISWAP_ROUTER_ADDRESS =
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
const RINKEBY_ALLOWANCE_MODULE_ADDRESS =
  '0xCFbFaC74C26F8647cBDb8c5caf80BB5b32E43134';

// mainnet
const MAINNET_PROXY_FACTORY_ADDRESS =
  '0x76E2cFc1F5Fa8F6a5b3fC4c8F4788F0116861F9B';
const MAINNET_GNOSIS_SAFE_ADDRESS =
  '0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F';
const MAINNET_USDC_ADDRESS = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
const MAINNET_USDT_ADDRESS = '0xdac17f958d2ee523a2206206994597c13d831ec7';
const MAINNET_DAI_ADDRESS = '0x6b175474e89094c44da98b954eedeac495271d0f';
const MAINNET_WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
const MAINNET_MULTISEND_ADDRESS = '0x8D29bE29923b68abfDD21e541b9374737B49cdAD';
const MAINNET_UNISWAP_ROUTER_ADDRESS =
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
const MAINNET_ALLOWANCE_MODULE_ADDRESS =
  '0xCFbFaC74C26F8647cBDb8c5caf80BB5b32E43134';

//polygon
const POLYGON_PROXY_FACTORY_ADDRESS =
  '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2';
const POLYGON_MULTISEND_ADDRESS = '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761';
const POLYGON_GNOSIS_SAFE_ADDRESS =
  '0x3E5c63644E683549055b9Be8653de26E0B4CD36E';

const addresses = {
  [networkNames.MAINNET]: {
    PROXY_FACTORY_ADDRESS: MAINNET_PROXY_FACTORY_ADDRESS,
    GNOSIS_SAFE_ADDRESS: MAINNET_GNOSIS_SAFE_ADDRESS,
    DAI_ADDRESS: MAINNET_DAI_ADDRESS,
    USDC_ADDRESS: MAINNET_USDC_ADDRESS,
    USDT_ADDRESS: MAINNET_USDT_ADDRESS,
    WETH_ADDRESS: MAINNET_WETH_ADDRESS,
    MULTISEND_ADDRESS: MAINNET_MULTISEND_ADDRESS,
    UNISWAP_ROUTER_ADDRESS: MAINNET_UNISWAP_ROUTER_ADDRESS,
    ALLOWANCE_MODULE_ADDRESS: MAINNET_ALLOWANCE_MODULE_ADDRESS,
    ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
    OTHER_ZERO_ADDRESS: '0x0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  },
  [networkNames.RINKEBY]: {
    PROXY_FACTORY_ADDRESS: RINKEBY_PROXY_FACTORY_ADDRESS,
    GNOSIS_SAFE_ADDRESS: RINKEBY_GNOSIS_SAFE_ADDRESS,
    DAI_ADDRESS: RINKEBY_DAI_ADDRESS,
    USDC_ADDRESS: RINKEBY_USDC_ADDRESS,
    USDT_ADDRESS: RINKEBY_USDT_ADDRESS,
    WETH_ADDRESS: RINKEBY_WETH_ADDRESS,
    MULTISEND_ADDRESS: RINKEBY_MULTISEND_ADDRESS,
    UNISWAP_ROUTER_ADDRESS: RINKEBY_UNISWAP_ROUTER_ADDRESS,
    ALLOWANCE_MODULE_ADDRESS: RINKEBY_ALLOWANCE_MODULE_ADDRESS,
    ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
    OTHER_ZERO_ADDRESS: '0x0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  },
  [networkNames.POLYGON]: {
    PROXY_FACTORY_ADDRESS: POLYGON_PROXY_FACTORY_ADDRESS,
    GNOSIS_SAFE_ADDRESS: POLYGON_GNOSIS_SAFE_ADDRESS,
    MULTISEND_ADDRESS: POLYGON_MULTISEND_ADDRESS,
    // ALLOWANCE_MODULE_ADDRESS: RINKEBY_ALLOWANCE_MODULE_ADDRESS /* not available as on 29th june  */,
    ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
    OTHER_ZERO_ADDRESS: '0x0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',

    /* not used now
      DAI_ADDRESS: RINKEBY_DAI_ADDRESS,
      USDC_ADDRESS: RINKEBY_USDC_ADDRESS,
      USDT_ADDRESS: RINKEBY_USDT_ADDRESS,
      WETH_ADDRESS: RINKEBY_WETH_ADDRESS,
      UNISWAP_ROUTER_ADDRESS: RINKEBY_UNISWAP_ROUTER_ADDRESS 
    */
  },
};

export default addresses[process.env.REACT_APP_NETWORK_NAME];
