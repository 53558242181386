import AssetsIconActive from 'assets/icons/sidebar/assets-active.svg';
import AssetsIcon from 'assets/icons/sidebar/assets.svg';
import DashboardIconActive from 'assets/icons/sidebar/dashboard-active.svg';
import DashboardIcon from 'assets/icons/sidebar/dashboard.svg';
import PeopleIconActive from 'assets/icons/sidebar/people-active.svg';
import PeopleIcon from 'assets/icons/sidebar/people.svg';
import SettingsIconActive from 'assets/icons/sidebar/settings-active.svg';
import SettingsIcon from 'assets/icons/sidebar/settings.svg';
import TransactionsIconActive from 'assets/icons/sidebar/transactions-active.svg';
import TransactionsIcon from 'assets/icons/sidebar/transactions.svg';
import { routeTemplates } from 'constants/routes/templates';

export const mainNavItems = [
  {
    link: routeTemplates.dashboard.root,
    name: 'Dashboard',
    icon: DashboardIcon,
    activeIcon: DashboardIconActive,
  },
  {
    link: routeTemplates.dashboard.people.root,
    name: 'People',
    icon: PeopleIcon,
    activeIcon: PeopleIconActive,
  },
  {
    link: routeTemplates.dashboard.assets,
    name: 'Assets',
    icon: AssetsIcon,
    activeIcon: AssetsIconActive,
  },
  {
    link: routeTemplates.dashboard.transactions,
    name: 'Transactions',
    icon: TransactionsIcon,
    activeIcon: TransactionsIconActive,
  },
  {
    link: routeTemplates.dashboard.settings,
    name: 'Settings',
    icon: SettingsIcon,
    activeIcon: SettingsIconActive,
  },
];
