import React from 'react';
import { useSelector } from 'react-redux';

import Img from 'components/common/Img';
import { getDefaultIconIfPossible } from 'constants/index';
import { makeSelectTokenIcons } from 'store/tokens/selectors';

export default function TokenImg({ icon, token, width, className, ...rest }) {
  const icons = useSelector(makeSelectTokenIcons());

  if (token === 'USD') {
    return (
      <span
        style={{
          marginRight: '0.25rem',
          width: '1.6rem',
          height: '1.6rem',
          display: 'inline-block',
          textAlign: 'center',
          lineHeight: '1.6rem',
          borderRadius: '100%',
          border: '0.1px solid black',
        }}
      >
        $
      </span>
    );
  }

  return (
    <Img
      src={icon ? icon : getDefaultIconIfPossible(token, icons)}
      alt={token || 'token'}
      className={className || 'mr-1'}
      width={width || '16'}
      {...rest}
    />
  );
}
