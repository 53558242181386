export const TRANSACTION_MODES = {
  MASS_PAYOUT: 0,
  QUICK_TRANSFER: 1,
  SPENDING_LIMITS: 2,
};

export const TRANSACTION_STATUS = {
  COMPLETED: 0,
  PENDING: 1,
  FAILED: 2,
};
