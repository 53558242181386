import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocalStorage } from 'hooks';

import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import { getTransactionById } from 'store/transactions/actions';
import {
  makeSelectFetching,
  makeSelectTransactionDetails,
} from 'store/transactions/selectors';
import safeReducer from 'store/safe/reducer';
import safeSaga from 'store/safe/saga';
import metaTxReducer from 'store/metatx/reducer';
import metaTxSaga from 'store/metatx/saga';
import { getMetaTxEnabled } from 'store/metatx/actions';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import {
  makeSelectOwnerSafeAddress,
  makeSelectOrganisationType,
} from 'store/global/selectors';
import Loading from 'components/common/Loading';
import { InfoCard } from 'components/People/styles';
import { DescriptionCard, DisbursementCard, FinalStatus } from './styles';
import { getDecryptedDetails } from 'utils/encryption';
import DisbursementDetails from './DisbursementDetails';
import Summary from './Summary';

const transactionsKey = 'transactions';
const safeKey = 'safe';
const metaTxKey = 'metatx';

export default function Transactions() {
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');

  // Reducers
  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });

  // Sagas
  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });
  useInjectSaga({ key: safeKey, saga: safeSaga });
  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });

  const dispatch = useDispatch();
  const params = useParams();

  const loading = useSelector(makeSelectFetching());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const transactionDetails = useSelector(makeSelectTransactionDetails());
  const organisationType = useSelector(makeSelectOrganisationType());

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getMetaTxEnabled(ownerSafeAddress));
    }
  }, [dispatch, ownerSafeAddress]);

  useEffect(() => {
    const transactionId = params && params.transactionId;
    if (ownerSafeAddress && transactionId) {
      dispatch(getTransactionById(ownerSafeAddress, transactionId));
    }
  }, [dispatch, ownerSafeAddress, params]);

  const renderFinalStatus = () => {
    switch (transactionDetails.status) {
      case 0:
        return <div className="success">Success</div>;
      case 1:
        return <div className="pending">Pending</div>;
      case 2:
        return <div className="failed">Failed</div>;
      default:
        return '';
    }
  };

  const renderTransactionDetails = () => {
    if (loading)
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: '40rem' }}
        >
          <Loading color="primary" width="3rem" height="3rem" />
        </div>
      );

    if (!transactionDetails || !encryptionKey) return null;

    const { transactionHash, tokenCurrency, to, transactionMode, description } =
      transactionDetails;

    const paidTeammates = getDecryptedDetails(
      to,
      encryptionKey,
      organisationType
    );

    return (
      <div>
        <InfoCard style={{ minHeight: '0' }}>
          <div>
            <div className="title mb-0">Transaction Status</div>
          </div>
          <FinalStatus>{renderFinalStatus()}</FinalStatus>
        </InfoCard>

        <DescriptionCard>
          <div className="title">Description</div>
          <div className="subtitle">
            {description ? description : `No description given...`}
          </div>
        </DescriptionCard>

        <DisbursementCard>
          <div className="title">Disbursement Details</div>
          <DisbursementDetails
            paidTeammates={paidTeammates}
            transactionMode={transactionMode}
            tokenCurrency={tokenCurrency}
          />
        </DisbursementCard>

        {transactionHash && (
          <Summary
            txDetails={transactionDetails}
            paidTeammates={paidTeammates}
          />
        )}
      </div>
    );
  };

  return renderTransactionDetails();
}
