import Big from 'big.js';

export function formatNumber(x, decimals = 2) {
  const [whole, fraction] = Big(x).round(decimals).toString().split('.');
  let wholeWithCommas = whole
    .split('')
    .reverse()
    .map((current, index, str) =>
      index > 0 && index < str.length && index % 3 === 0
        ? current + ','
        : current
    )
    .reverse()
    .join('');

  const ret = fraction
    ? wholeWithCommas.concat('.', fraction)
    : wholeWithCommas;

  return ret;
  // return Big(x)
  //   .round(decimals)
  //   .toString()
  //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}
