import { faAngleDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SafeIcon from 'assets/icons/navbar/safe.svg';
import QRCodeLogo from 'assets/icons/sidebar/qr-code.svg';
import CopyButton from 'components/common/Copy';
import EtherscanLink from 'components/common/EtherscanLink';
import Img from 'components/common/Img';
import { ChainId, minifyAddress } from 'components/common/Web3Utils';
import AddFundsModal, {
  MODAL_NAME as ADD_FUNDS_MODAL,
} from 'components/Payments/AddFundsModal';
import { findNetworkNameByWeb3ChainId } from 'constants/networks';
import { startCase } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { show } from 'redux-modal';
import {
  makeSelectOrganisationName,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import { toggleSafeDetails } from 'store/layout/actions';
import logoutSaga from 'store/logout/saga';
import { makeSelectTotalBalance } from 'store/tokens/selectors';
import { useInjectSaga } from 'utils/injectSaga';
import { formatNumber } from 'utils/number-helpers';
import { mainNavItems } from './navItems';
import NewTransferDropdown from './NewTransferDropdown';
import { DashboardSidebar } from './styles';

const logoutKey = 'logout';

export default function Sidebar({ isSidebarOpen, closeSidebar }) {
  const location = useLocation();
  const dispatch = useDispatch();

  useInjectSaga({ key: logoutKey, saga: logoutSaga });

  const orgName = useSelector(makeSelectOrganisationName());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const totalBalance = useSelector(makeSelectTotalBalance());
  const networkName = startCase(findNetworkNameByWeb3ChainId(ChainId()));

  const renderNavItem = ({ link, name, icon, activeIcon }) => {
    const active = location.pathname === link;
    return (
      <Link
        key={name}
        to={link}
        className={`menu-item  d-flex align-items-center ${
          active && 'menu-item-highlighted'
        }`}
      >
        <div className="icon">
          <Img src={active ? activeIcon : icon} alt={name} />
        </div>
        <div className="name">{name}</div>
      </Link>
    );
  };

  const openSafeDetails = () => {
    dispatch(toggleSafeDetails(true));
  };

  const showAddFundsModal = () => {
    dispatch(show(ADD_FUNDS_MODAL));
  };

  return (
    <DashboardSidebar
      className={`${isSidebarOpen ? 'sidebar-responsive' : ''}`}
    >
      <div className="sidebar-container">
        <div className="close-btn" onClick={closeSidebar}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
        <div className="network-name d-flex align-items-center">
          {networkName}
        </div>
        <div className="safe-info-container d-flex align-items-center">
          <Img src={SafeIcon} alt="safe-image" className="image" />
          <div className="name d-flex" onClick={openSafeDetails}>
            {orgName}
            <FontAwesomeIcon icon={faAngleDown} color="#373737" />
          </div>
          <div className="address">{minifyAddress(ownerSafeAddress)}</div>
          <div className="actions-container d-flex align-items-center">
            <div onClick={showAddFundsModal} style={{ position: 'relative' }}>
              <Img
                src={QRCodeLogo}
                className="qr-logo"
                id="qr-logo"
                data-for={'qr-logo'}
                data-tip={'Add Funds'}
                alt="qr-logo"
              />
              <ReactTooltip
                id={'qr-logo'}
                place={'top'}
                type={'dark'}
                effect={'solid'}
                getContent={dataTip => dataTip}
              />
            </div>
            <CopyButton
              id="address"
              tooltip="safe address"
              value={ownerSafeAddress}
            />
            <EtherscanLink
              id="etherscan-link-navbar-safe"
              type="address"
              address={ownerSafeAddress}
            />
          </div>
          <div className="amount">
            {totalBalance &&
              '$' +
                formatNumber(totalBalance.split('.')[0], 0) +
                '.' +
                totalBalance.split('.')[1]}
          </div>
          <div className="cta">
            <NewTransferDropdown />
          </div>
        </div>

        <div className="menu-items">
          {mainNavItems.map(navItem => renderNavItem(navItem))}
        </div>
        <AddFundsModal />
      </div>
    </DashboardSidebar>
  );
}
