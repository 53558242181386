import React from 'react';
import AES from 'crypto-js/aes';

import Img from 'components/common/Img';
import Button from 'components/common/Button';

import SomethingWentWrongPNG from 'assets/images/something-went-wrong.png';

// To get the error out of the random string we get:
//
// const hex2a = (hexString) => {
//   let str = '';
//   for (let i = 0; i < hex.length; i += 2)
//       str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
//   return str;
// }
// console.log(hex2a(String(AES.decrypt(error, 'error_message_encryption_secret'))));

export default function ErrorPage(props) {
  const plaintextError =
    props.error.message +
    '\n' +
    props.error.stack.split('\n').slice(0, 5).join('\n');
  const error = String(
    AES.encrypt(plaintextError, 'error_message_encryption_secret')
  );

  const redirectToDashboard = () => {
    window.location = '/dashboard';
  };

  const copyCode = () => {
    navigator.clipboard.writeText(error);
  };

  const gotoSupport = () => {
    window.open('https://discord.gg/Kg78anBhsM');
  };

  return (
    <div
      style={{
        display: 'flex',
        maxWidth: '100%',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '65%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}
      >
        <Img
          style={{
            position: 'relative',
            top: 0,
          }}
          src={SomethingWentWrongPNG}
          alt=""
          width={500}
          height={400}
        />
        <div
          style={{
            fontSize: 28,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '36px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginTop: 96,
          }}
        >
          Something went wrong
        </div>
        <div
          style={{
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '27px',
            letterSpacing: '0.01em',
            textAlign: 'center',
            marginTop: 16,
            maxWidth: 560,
          }}
        >
          We apologize for the inconvenience. We request you to copy the error
          message and post on our support channel.
        </div>
        <Button
          style={{
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '22px',
            letterSpacing: '0em',
            textAlign: 'left',
            padding: '14px 26px 12px',
            maxHeight: 48,
            marginTop: 40,
          }}
          onClick={redirectToDashboard}
        >
          Go back to dashboard
        </Button>
      </div>
      <div
        style={{
          position: 'relative',
          width: '35%',
          padding: '64px 48px',
          backgroundColor: '#fafbfc',
        }}
      >
        <div
          style={{
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '27px',
            letterSpacing: '0.05em',
            textAlign: 'left',
            color: '#ff4b55',
          }}
        >
          ERROR MESSAGE
        </div>
        <div
          style={{
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '25px',
            letterSpacing: '0.02em',
            textAlign: 'left',
            marginTop: 24,
            wordBreak: 'break-all',
          }}
        >
          {error}
        </div>
        <div
          style={{
            position: 'absolute',
            left: 48,
            bottom: 48,
            width: '100%',
            display: 'flex',
            gap: 16,
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '22px',
              letterSpacing: '0em',
              textAlign: 'left',
              padding: '14px 26px 12px',
              maxHeight: 48,
            }}
            onClick={copyCode}
          >
            Copy code
          </Button>
          <Button
            style={{
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '22px',
              letterSpacing: '0em',
              textAlign: 'left',
              padding: '14px 26px 12px',
              maxHeight: 48,
            }}
            onClick={gotoSupport}
          >
            Go to Support
          </Button>
        </div>
      </div>
    </div>
  );
}
