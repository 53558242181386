export const ADD_TRANSACTION = 'parcel/transactions/ADD_TRANSACTION';
export const ADD_TRANSACTION_SUCCESS =
  'parcel/transactions/ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_ERROR =
  'parcel/transactions/ADD_TRANSACTION_ERROR';

export const VIEW_TRANSACTIONS = 'parcel/transactions/VIEW_TRANSACTIONS';
export const VIEW_TRANSACTIONS_SUCCESS =
  'parcel/transactions/VIEW_TRANSACTIONS_SUCCESS';
export const VIEW_TRANSACTIONS_ERROR =
  'parcel/transactions/VIEW_TRANSACTIONS_ERROR';

export const GET_TRANSACTION_BY_ID =
  'parcel/transactions/GET_TRANSACTION_BY_ID';
export const GET_TRANSACTION_BY_ID_SUCCESS =
  'parcel/transactions/GET_TRANSACTION_BY_ID_SUCCESS';
export const GET_TRANSACTION_BY_ID_ERROR =
  'parcel/transactions/GET_TRANSACTION_BY_ID_ERROR';

export const CLEAR_TRANSACTION_HASH =
  'parcel/transactions/CLEAR_TRANSACTION_HASH';
